<template>
  <v-card
    flat
    :loading="loading"
    :height="height"
    outlined
    style="overflow-y: auto"
    rounded="lg"
  >
    <v-form :disabled="loading">
      <v-card-title class="mb-4 font-weight-semibold">
        {{ $t('customer.orders.orderDetailsForm') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :xl="4" :lg="4" :md="6" :sm="12" v-if="isManualCreationMode">
            <cz-form-field
              :label="$t('customer.orders.orderShortDesc')"
              :required="!readOnlyMode"
            >
              <validation-provider
                :rules="isManualCreationMode ? 'required|max:40' : 'max:40'"
                :name="$t('customer.orders.orderShortDesc')"
                v-slot="{ errors }"
              >
                <cz-input
                  :value="value.title"
                  dense
                  @input="$emit('field-update', 'title', $event)"
                  :error-messages="errors"
                  :counter="40"
                  :max-length="40"
                  :readonly="readOnlyMode"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="4" :md="6" :sm="12">
            <cz-form-field
              :label="$t('customer.orders.orderNumber')"
              :required="!readOnlyMode"
            >
              <div v-if="!order._id && isManualCreationMode">
                <div class="d-flex align-center" style="column-gap: 5px">
                  <div class="text-body-1">
                    {{ $t('customer.orders.orderAutoAllocated') }}
                  </div>
                  <v-menu offset-y :nudge-bottom="10">
                    <template v-slot:activator="{ on, attrs }">
                      <cz-button
                        :icon-src="mdiInformationOutline"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        text
                      />
                    </template>
                    <v-card :max-width="300" :min-width="180">
                      <v-card-text class="text-pre-wrap">
                        {{
                          $t('customer.orders.orderAutoAllocatedHint', [
                            order.note
                          ])
                        }}
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <validation-provider
                v-else
                rules="required"
                :name="$t('customer.orders.orderNumber')"
                v-slot="{ errors }"
              >
                <cz-input
                  :value="value.orderNumber"
                  @input="$emit('field-update', 'orderNumber', $event)"
                  type="number"
                  dense
                  :error-messages="errors"
                  :readonly="readOnlyMode || isManualCreationMode"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="4" :md="6" :sm="12">
            <cz-form-field
              :label="$t('customer.orders.orderStatus')"
              :required="!readOnlyMode"
            >
              <div class="d-flex align-center" style="column-gap: 5px">
                <div class="text-body-1">
                  {{ orderStatusText[order.status] }}
                </div>
                <v-menu offset-y :nudge-bottom="10">
                  <template v-slot:activator="{ on, attrs }">
                    <cz-button
                      :icon-src="mdiInformationOutline"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      text
                    />
                  </template>
                  <v-card
                    :max-width="300"
                    :min-width="180"
                    v-if="orderStatusHint"
                  >
                    <v-card-text v-if="order.returnType" class="text-pre-wrap">
                      <div class="text-body-1">
                        {{
                          $t(`customer.orders.returnTypes.${order.returnType}`)
                        }}
                      </div>
                      <div v-if="order.note" class="mt-2">
                        {{ $t('common.note2', [order.note]) }}
                      </div>
                    </v-card-text>
                    <v-card-text
                      v-else-if="order.rejectType"
                      class="text-pre-wrap"
                    >
                      <div>
                        {{
                          $t(`customer.orders.rejectTypes.${order.rejectType}`)
                        }}
                      </div>
                      <div v-if="order.note" class="mt-2">
                        {{ $t('common.note2', [order.note]) }}
                      </div>
                    </v-card-text>
                    <v-card-text v-else class="text-pre-wrap">
                      {{ orderStatusHint }}
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </cz-form-field>
          </v-col>

          <v-col :xl="4" :lg="4" :md="6" :sm="12">
            <cz-form-field
              :label="$t('customer.orders.issueDate')"
              :required="!readOnlyMode"
            >
              <validation-provider
                rules="required|date_format:yyyy-MM-dd"
                :name="$t('customer.orders.issueDate')"
                v-slot="{ errors }"
              >
                <cz-new-date-picker
                  :value="value.issueDate"
                  @input="$emit('field-update', 'issueDate', $event)"
                  open-menu-on-click
                  :readonly="readOnlyMode"
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="4" :md="6" :sm="12">
            <cz-form-field
              :label="$t('customer.orders.vendorId')"
              :required="!readOnlyMode"
            >
              <validation-provider
                rules="required|length:9"
                :name="$t('customer.orders.vendorId')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="vendorId"
                  @input="$emit('field-update', 'vendorId', $event)"
                  :hint="!readOnlyMode ? $t('common.autocomplete') : ''"
                  hide-no-data
                  :error-messages="errors"
                  no-filter
                  dense
                  :append-icon="null"
                  :items="vendors"
                  :readonly="readOnlyMode"
                  combobox
                  removeable
                  :loading="serachingVendors"
                  :placeholder="$t('customer.orders.vendorSearchPlaceholder')"
                  v-debounce:600ms="onVendorsSearch"
                  item-text="businessName"
                  item-value="_id"
                  @change="$emit('on-vendor-change', $event)"
                  :clearable="!readOnlyMode"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col
            :xl="4"
            :lg="4"
            :md="6"
            :sm="12"
            v-if="!selectedVendor && order.vendorId && !loadingVendor"
          >
            <cz-form-field
              :label="$t('vendor.onboarding.businessType')"
              required
            >
              <validation-provider
                rules="required"
                :name="$t('vendor.onboarding.businessType')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  :value="vendorBusinessType"
                  :items="businessTypes"
                  dense
                  :error-messages="errors"
                  :readonly="readOnlyMode"
                  @change="$emit('on-vendor-business-type-change', $event)"
                  clearable
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="4" :md="6" :sm="12">
            <cz-form-field :label="$t('customer.orders.vendorName')">
              <div v-if="selectedVendor" class="text-body-1">
                {{ selectedVendor.businessName }}
              </div>

              <div v-else class="d-flex align-center" style="column-gap: 10px">
                <div class="negative--text text-body-1 font-weight-semibold">
                  {{ $t('customer.orders.vendorNotFound') }}
                </div>
                <v-menu offset-y :nudge-bottom="10" v-if="order.vendorId">
                  <template v-slot:activator="{ on, attrs }">
                    <cz-icon
                      :src="mdiInformationOutline"
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                    />
                  </template>
                  <v-card :max-width="350">
                    <v-card-text class="text-pre-wrap">
                      {{ $t('customer.orders.vendorNotFoundHint') }}
                    </v-card-text>
                    <v-card-actions>
                      <cz-button
                        color="primary"
                        :title="$t('customer.orders.inviteVendor')"
                        @click="$emit('on-vendor-invite')"
                        :icon-src="mdiEmailFastOutline"
                        small
                      />
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </cz-form-field>
          </v-col>
        </v-row>

        <template v-if="orderPriceSectionVisible">
          <v-card-title class="px-0 mb-2 font-weight-semibold">
            {{ $t('customer.orders.orderAmountDetails') }}
          </v-card-title>
          <v-row>
            <v-col :xl="4" :lg="4" :md="6" :sm="12" class="py-0">
              <cz-form-field
                :label="$t('customer.orders.currency')"
                :required="!readOnlyMode"
              >
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.currency')"
                  v-slot="{ errors }"
                >
                  <cz-currency-picker
                    v-model="currency"
                    @input="$emit('field-update', 'currency', $event)"
                    :items="currencyTypes"
                    dense
                    :error-messages="errors"
                    outlined
                    :placeholder="$t('customer.orders.selectCurrency')"
                    :readonly="readOnlyMode"
                  />
                </validation-provider>
              </cz-form-field>
            </v-col>
          </v-row>
          <v-row v-if="order.currency">
            <v-col :xl="4" :lg="4" :md="6" :sm="12" class="py-0">
              <cz-form-field
                :label="$t('customer.orders.netAmount')"
                :required="!readOnlyMode"
              >
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.netAmount')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="netAmount"
                    type="number"
                    dense
                    :error-messages="errors"
                    :readonly="readOnlyMode"
                  />
                </validation-provider>
              </cz-form-field>
            </v-col>
            <v-col :xl="4" :lg="4" :md="6" :sm="12" class="py-0">
              <cz-form-field
                :label="$t('customer.orders.vatAmount')"
                :required="!readOnlyMode"
              >
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.vatAmount')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="vatAmount"
                    dense
                    :error-messages="errors"
                    :type="isManualCreationMode ? 'text' : 'number'"
                    :readonly="readOnlyMode || isManualCreationMode"
                  />
                </validation-provider>
              </cz-form-field>
            </v-col>
            <v-col :xl="4" :lg="4" :md="6" :sm="12" class="py-0">
              <cz-form-field
                :label="$t('customer.orders.totalAmount')"
                :required="!readOnlyMode"
              >
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.totalAmount')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="totalAmount"
                    dense
                    :error-messages="errors"
                    :readonly="readOnlyMode || isManualCreationMode"
                    :type="isManualCreationMode ? 'text' : 'number'"
                  />
                </validation-provider>
              </cz-form-field>
            </v-col>
          </v-row>
        </template>

        <template>
          <v-card-title class="px-0 mb-2 font-weight-semibold">
            {{ $t('customer.orders.orderAdditionalInfo') }}
          </v-card-title>
          <v-row>
            <v-col
              :xl="4"
              :lg="4"
              :md="6"
              :sm="12"
              v-if="systemTags && systemTags.length"
            >
              <cz-form-field :label="$t('common.tags')">
                <cz-input-tags
                  :value="value.tags"
                  @input="$emit('field-update', 'tags', $event)"
                  :items="systemTags"
                  dense
                  small
                  :readonly="readOnlyMode"
                  text-color="white"
                  color="buttonSecondary darken-2"
                />
              </cz-form-field>
            </v-col>
            <v-col :xl="4" :lg="4" :md="6" :sm="12" v-if="isManualCreationMode">
              <cz-form-field :label="$t('customer.orders.orderDescription')">
                <cz-input
                  multi-line
                  :value="value.description"
                  @input="$emit('field-update', 'description', $event)"
                  no-resize
                  dense
                  :rows="1"
                  auto-grow
                  :counter="500"
                  :max-length="500"
                  :readonly="readOnlyMode"
                />
              </cz-form-field>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-form>
    <v-card-text>
      <additional-documents
        v-if="order"
        v-model="additionalDocumentsLocal"
        :exisiting-documents="order.additionalDocuments || []"
        :document-types="addtionalDocumentTypes"
        :allow-add-documents="!readOnlyMode"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {
  CzCurrencyPicker,
  CzNewDatePicker,
  CzInputTags,
  CzInput,
  CzFormField,
  CzIcon,
  CzAutocomplete,
  CzButton
} from '@/components';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import currencyTypes from '@/shared/types/CurrencyTypes';
import AdditionalDocuments from '@/domain/shared/components/AdditionalDocuments.vue';
import OrderDocumentType from '@/shared/types/OrderDocumentType';
import {
  mdiInformationOutline,
  mdiEmailFastOutline,
  mdiChevronDown
} from '@mdi/js';
import OrderStatus from '@/shared/types/OrderStatus';
import { typesMixin } from '@/shared/mixins';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { getVendors } from '@/core/api';

export default {
  name: 'OrderDetails',
  components: {
    CzCurrencyPicker,
    CzInputTags,
    CzInput,
    CzFormField,
    AdditionalDocuments,
    CzNewDatePicker,
    CzAutocomplete,
    CzIcon,
    CzButton
  },
  props: {
    value: {
      type: Object
    },
    height: {
      type: [String, Number],
      default: 'calc(100vh - 320px)'
    },
    additionalDocuments: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    isManualCreationMode: {
      type: Boolean
    },
    isInApprovalRound: {
      type: Boolean
    },
    readOnlyMode: {
      type: Boolean
    },
    systemTags: {
      type: Array,
      default: () => []
    },
    createMode: {
      type: Boolean
    },
    selectedVendor: {
      type: Object,
      default: () => null
    },
    vendorBusinessType: {
      type: String
    },
    vendorVat: {
      type: Number
    },
    loadingVendor: {
      type: Boolean
    },
    orderPriceSectionVisible: {
      type: Boolean
    }
  },
  mixins: [typesMixin],
  created() {
    this.order = cloneDeep(this.value);
    this.additionalDocumentsLocal = cloneDeep(this.additionalDocuments);
  },
  computed: {
    orderStatusHint() {
      switch (this.order?.status) {
        case OrderStatus.NEW_PREDICTED:
          return this.$t('customer.orders.status.new_predicted_hint');
        case OrderStatus.NEW:
          return this.$t('customer.orders.status.new_hint');
        case OrderStatus.OPEN_PENDING_VENDOR_REG:
          return this.$t(
            'customer.orders.status.open_pending_vendor_registration_hint'
          );
        case OrderStatus.CLOSED:
          return this.$t('customer.orders.status.closed_hint');
        case OrderStatus.CANCELLED:
          return this.$t('customer.orders.status.cancelled_hint', [
            this.order.cancellationReason
          ]);
        case OrderStatus.PENDING_APPROVAL:
          return this.$t('customer.orders.status.pending_approval_hint');
        case OrderStatus.RETURNED:
          return this.$t('customer.orders.status.returned_hint');
        case OrderStatus.REJECTED:
          return this.$t('customer.orders.status.rejected_hint');
        default:
          return null;
      }
    },
    vendorVatPercent() {
      if (this.vendorVat > 0) {
        return `${this.vendorVat * 100}%`;
      }
      return this.$t('customer.orders.noVat');
    },
    vendorId: {
      set(value) {
        this.$emit('field-update', 'vendorId', value);
      },
      get() {
        return this.value.vendorId;
      }
    },
    currency: {
      set(value) {
        this.$emit('field-update', 'currency', value);
      },
      get() {
        return this.value.currency;
      }
    },
    netAmount: {
      set(value) {
        this.$emit('field-update', 'netAmount', parseFloat(value));
        if (this.isManualCreationMode) {
          this.calculateOrderTotalAmount(value);
        }
      },
      get() {
        return this.value.netAmount;
      }
    },
    vatAmount: {
      set(value) {
        this.$emit('field-update', 'vatAmount', value);
      },
      get() {
        if (this.isManualCreationMode) {
          return `${formatAmountWithCurrency(this.value.vatAmount)} (${
            this.vendorVatPercent
          })`;
        } else {
          return this.value.vatAmount;
        }
      }
    },
    totalAmount: {
      set(value) {
        this.$emit('field-update', 'totalAmount', value);
      },
      get() {
        if (this.isManualCreationMode) {
          return formatAmountWithCurrency(this.value.totalAmount);
        } else {
          return this.value.totalAmount;
        }
      }
    },
    addtionalDocumentTypes() {
      return [
        {
          text: this.$t('orderDocumentTypes.bid'),
          value: OrderDocumentType.BID
        },
        {
          text: this.$t('orderDocumentTypes.contract'),
          value: OrderDocumentType.CONTRACT
        },
        {
          text: this.$t('orderDocumentTypes.script'),
          value: OrderDocumentType.SCRIPT
        },
        {
          text: this.$t('orderDocumentTypes.copy'),
          value: OrderDocumentType.COPY
        },
        {
          text: this.$t('orderDocumentTypes.wrote_quantities'),
          value: OrderDocumentType.WROTE_QUANTITIES
        },
        {
          text: this.$t('orderDocumentTypes.insurance_policy'),
          value: OrderDocumentType.INSURANCE_POLICY
        },
        {
          text: this.$t('orderDocumentTypes.other'),
          value: OrderDocumentType.OTHER
        }
      ];
    }
  },
  data() {
    return {
      mdiChevronDown,
      mdiInformationOutline,
      mdiEmailFastOutline,
      currencyTypes,
      order: null,
      additionalDocumentsLocal: [],
      vendors: [],
      serachingVendors: false
    };
  },
  methods: {
    calculateOrderTotalAmount(value) {
      if (!this.orderPriceSectionVisible) {
        return;
      }

      const val = value ? parseFloat(value) : 0;
      this.vatAmount = parseFloat(val) * this.vendorVat;
      this.totalAmount = val * (1 + this.vendorVat);
    },
    async onVendorsSearch(value) {
      if (!value) {
        this.vendors = [];
        return;
      }
      try {
        this.serachingVendors = true;
        this.vendors = await getVendors({
          search: value,
          isTest: false
        });
      } finally {
        this.serachingVendors = false;
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.order)) {
          this.order = cloneDeep(value);
        }
      }
    },
    additionalDocuments: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.additionalDocumentsLocal)) {
          this.additionalDocumentsLocal = cloneDeep(value);
        }
      }
    },
    additionalDocumentsLocal: {
      deep: true,
      handler(value) {
        this.$emit('update:additionalDocuments', value);
      }
    },
    selectedVendor: {
      handler() {
        if (this.isManualCreationMode) {
          this.calculateOrderTotalAmount(this.value.netAmount);
        }
      }
    },
    vendorBusinessType: {
      handler() {
        if (this.isManualCreationMode) {
          this.calculateOrderTotalAmount(this.value.netAmount);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
